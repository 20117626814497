import React, { useEffect, useState } from "react";
import {FaMoneyBillWave} from "react-icons/fa";
export const ExpenseDate = ({month,year,title}) =>
{
    const [date,setDate] = useState();
    useEffect(
        () =>{
            switch(month){
                case "1" :
                    setDate(`January, ${year}`);
                    console.log(`${month}`);
                    break;
                case "2" :
                    setDate(`February, ${year}`);
                    console.log(`${month}`);
                    break;
                case "3" :
                    setDate(`Mars, ${year}`);
                     break;
                case "4" :
                    setDate(`April, ${year}`);
                    break;
                case "5" :
                    setDate(`May, ${year}`);
                    break;
                case "6" :
                    setDate(`June, ${year}`);
                    break;
                case "7":
                    setDate(`July, ${year}`);
                    break;
                case "8" :
                    setDate(`August, ${year}`);
                    break;
                case "9" :
                    setDate(`September, ${year}`);
                    break;
                case "10" :
                    setDate(`October, ${year}`);
                    break;   
                case "11" :
                    setDate(`November, ${year}`);
                    break; 
                case "12" :
                    setDate(`December, ${year}`);
                    break;            
                default :
                    console.log(`${month}`);   
            }
        },[month,year]
    );
    return(
        <>
            <h2 className="font-weight-normal text-muted"><FaMoneyBillWave className="m-1"/>{title} : {date}
            </h2>
        </>
        
    )
}