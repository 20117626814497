import React, { useEffect, useImperativeHandle, useState } from "react";
import axios from "axios";
import { BsPencilSquare } from "react-icons/bs";
import { Table, Button, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import authService from "../api-authorization/AuthorizeService";
export const Items = React.forwardRef(({groupid,groupname,month,year,refresh,action}, ref) =>{
    const [expenses,setExpenses] =useState([{}]) ;
    const [loading,setLoading] = useState(false) ;
    
  //  const editExpenseRef = useRef() ;
    // const [refresh,setRefresh] = useState(0);
    useImperativeHandle(ref,() => ({
        refreshItems(){
        //     console.log("xpense added refresh item component");
        //    setRefresh(++refresh);
        //    console.log(refresh);
        },
    }));
  
    useEffect(() => {
        const endpoint = groupname !== "Fixed" ? `api/expenses/GetexpensesBydate?groupid=${groupid}&&month=${month}&&year=${year}`
                                : "api/expenses/GetFixedExpenses";
    
        const getItems = async () => {
            const token = await authService.getAccessToken();
            await   axios.get(
                endpoint,
                {
                  headers: !token
                    ? {}
                    : {
                        'Authorization' : `Bearer ${token}`,
                      },
                }
            ).then(respone =>{
                setExpenses(respone.data);
                setLoading(false) ;
            }).catch(error => {
                console.log(error);
                setLoading(false) ;
            });
        }
        setLoading(true) ;
       groupname && getItems();
        return console.log("items unmount");    
    },[groupid,month,year,refresh]);
    const editTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Edit 
        </Tooltip>
    );
   
    return(
        <>
            <center style={{ display: loading ? "block" : "none" }}>
                <Spinner animation="grow" size="sm" variant="primary" />
            </center>
            <div style={{ display: loading ?  "none" : "block"  }}>
            <Table hover size="sm" >
                <thead className="thead-light">
                    <tr>
                        <th>
                            Descritpion
                        </th>
                        <th>
                            Amount DH
                        </th>
                        <th>
                            Amount Euro
                        </th>
                        <th>
                            Action
                        </th>
                    </tr>      
                </thead>
                <tbody>
                     {expenses.map( (expense,idx) =>{
                         return(
                            <tr key={idx}>
                            <td>
                                {expense.description}
                            </td>
                            <td>
                                {expense.amount_dh}
                            </td>
                            <td>
                                {expense.amount_euro}
                            </td>
                            <td>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={editTooltip}>
                                <Button variant="outline-danger" size="sm"
                                onClick={() => {action(expense)}}
                                ><BsPencilSquare/></Button>
                            </OverlayTrigger>
                            
                            </td>
                        </tr>
                         );
                     }

                     )}
                </tbody>
            </Table>   
        </div>
           
        </>
    );
});