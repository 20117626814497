import React, { useEffect, useState } from "react";
import { Container, Card, Row, Table, Spinner } from "react-bootstrap";
import axios from "axios";
import authService from "../api-authorization/AuthorizeService";
export const Echeance = () =>{
    const [totalecheances,setTotalecheances] = useState(0) ;
    const [echeancesNonRegle,setEcheancesNonRegle] = useState([{}]) ;
    const [loading,setLoading] = useState(false);
    const getEcheancesAmounts = async () =>{
        const token = await authService.getAccessToken();
        await  axios.get(
             "api/SalesReport/GetEcheancesAmounts",
             {
               headers: !token
                 ? {}
                 : {
                     'Authorization' : `Bearer ${token}`,
                   },
             }
        ).then(
             (response) => {
                setEcheancesNonRegle(response.data.echeanceNonRegle);
                setTotalecheances(response.data.total);
                setLoading(false);
             }
        ).catch(
            error => {console.log(error);
                 setLoading(false);
            }
             
         );
    }

    useEffect(() =>{
        //get Sales amount for each client from server
        setLoading(true);
        getEcheancesAmounts();
        return console.log("echeance unmount") ;
    },[]);
    return (
        <>            
<Container className="col-md-6 mb-4">
    <Card >
        <Card.Header className="text-center"  tag="h2">Echeances</Card.Header>
        <Card.Body>
            <Row>
            <center style={{ display: loading ? "block" : "none" }}>
                <Spinner animation="grow" size="sm" variant="primary" />
            </center>
                <div style={{ display: loading ?  "none" : "block"  }}>
                <Table hover size="sm" >
                <thead className="thead-light">
                            <tr>
                                <th>
                                    Total DH
                                </th>
                                <th>
                                {totalecheances.toFixed(2)} 
                                </th>
                            </tr>
                    </thead>
                    <thead className="thead-light">
                        <tr>
                            <th>
                                Client
                            </th>
                            <th>
                                Total Amount DH
                            </th>    
                        </tr>      
                    </thead>
                    <tbody>
                        {echeancesNonRegle.map( (echeance,idx) =>{
                            return(
                            <tr key={idx}>
                                <td>
                                    {echeance.client}
                                </td>
                                <td>
                                    {echeance.amount}
                                </td>
                            </tr>
                            );
                        }
                        )}    
                    </tbody>
                  
                </Table>
                </div>
               
            </Row>
        </Card.Body>
    </Card> 
    </Container>    
        </>
               
    )
};