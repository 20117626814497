import React, { useEffect, useRef, useState }  from "react";
import axios from 'axios';
import { Container, Row, Col, Button, Form, Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import { BsPencilSquare, BsPlusLg} from "react-icons/bs";
import {Items} from "../Item/Items";
import { EditGroup } from "../Modal/EditGroup";
import { AddGroup } from "../Modal/AddGroup";
import { AddExpense } from "../Modal/AddExpense";
import { EditExpense } from "../Modal/EditExpense";
import { Sales } from "./Sales";
import { ExpenseDate } from "./Date";
import { Purchases } from "./Purchases";
import authService from "../api-authorization/AuthorizeService";
export const Group = () => {
    var date = new Date();
    const [groups,setGroups] =useState([{}]);
    const [selectedGroupid,setSelectdGroupid] = useState();
    const [refresh,setRefresh] =useState(0);
    const [month,setMonth] =useState((date.getMonth()+1).toString());
    const [year,setYear] = useState(date.getFullYear());
    const [years,setYears] = useState([]);
    const addGroupRef = useRef();
    const editGroupRef = useRef();
    const editExpenseRef = useRef(); 
    const addExpneseRef = useRef();
    const itemRef = useRef();
    const MonthRef = useRef();
    const YearRef = useRef();
    const refreshAction = () =>{
        setRefresh(refresh + 1);
    };
    const editExpense = (expense) =>{
        editExpenseRef.current.editExpense(expense);
    }
    const editGroupTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Edit group
      </Tooltip>
    );
    const addGroupTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      add group
    </Tooltip>
    );
    const addExpenseTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        add expense 
      </Tooltip>
   );
    // const refreshItems = () => {
    //   itemRef.current.refreshItems();
    //}
    const getGroups = async () => {
      const token = await authService.getAccessToken();
      await axios.get(
         "api/groups/GetAllGroups",
         {
           headers: !token
             ? {}
             : {
                 Authorization: `Bearer ${token}`,
               },
         }).then((response) =>{
         setGroups(response.data);  
       }
       ).catch((error) =>{
         console.log("pop will show up to user with error message ");
       });
     };
     const getYears = async () =>{
        const token = await authService.getAccessToken();
         await  axios.get(
             "api/SalesReport/Getyears" ,
             {
               headers: !token
                 ? {}
                 : {
                     'Authorization' : `Bearer ${token}`,
                   },
             }
           ).then(
             response => {
               setYears(response.data) ;
             }
           ).catch(
             error => {
               console.log(error);
             }
           );
     };
    useEffect(() =>{
      //work with axios to send get request to get groups and items, expenses.   
      getGroups();
      getYears();
    },[refresh]);
    return(
      <Container>
        <ExpenseDate
        month={month}
        year={year}
        title="Expenses"
        />
        <Container border="secondary"> 
        <Row>
          <Col md="2">
          </Col>
          <Col md="4">
            <Form.Select ref={MonthRef}>
                <option  value={month}></option>
                <option  value="1">January</option>
                <option value="2">February</option>
                <option value="3">Mars</option>
                <option value="4">April</option>
                <option value="5">Mai</option>
                <option value="6">June</option>
                <option value="7">Julie</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
            </Form.Select>
          </Col>
          <Col  md="4">
            <Form.Select ref={YearRef}>
            <option  value={year}></option>
              {
                years.map((year,idx) =>{
                return (<option key={idx} value={year}>{year}</option>);
                })
              }
            </Form.Select>     
          </Col>
          <Col md="1">
            <Button variant="outline-primary"
            onClick={
              ()=>{
                setMonth(MonthRef.current.value);
                setYear(YearRef.current.value);
              }
            }>Laod
            </Button>
          </Col>
          <Col md="1">
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={addGroupTooltip}>
               <Button variant="outline-primary" className="ml-3"
                  onClick={
                    ()=>{
                      addGroupRef.current.addGroup();
                    }
                   }><BsPlusLg/>
               </Button>
            </OverlayTrigger>
           
          </Col>
        </Row>
        </Container>
        <Container className="row pt-4">
          <Sales 
          month={month}
          year={year}
          />
          <Purchases
          month={month}
          year={year}
          />
          {groups.map((group,idx) =>{
              return(
                <Container key={idx} className="col-md-6 mb-4">
                  <Card  className="text-center" >
                   <Card.Header  tag="h3">{group.groupName}</Card.Header>
                   <Card.Body>
                     <Row>
                       <Items
                       ref={itemRef}
                       groupid={group.id} 
                       groupname={group.groupName}
                       month={month}
                       year={year}
                       refresh={refresh}
                       action={editExpense}
                       />
                     </Row>
                     <Row className="float-right">
                       <Col xs="7">
                       </Col>
                       <Col>
                       <OverlayTrigger               
                               placement="right"
                               delay={{ show: 250, hide: 300 }}
                               overlay={editGroupTooltip}>
                            <Button variant="outline-danger" className="m-1"
                             style={{ display: group.groupName === "Fixed" ? "none" : "inline" }}
                             onClick={() => {editGroupRef.current.editGroup(group)}}
                             ><BsPencilSquare/></Button>
                       </OverlayTrigger>
                       <OverlayTrigger
                               placement="right"
                               delay={{ show: 250, hide: 300 }}
                               overlay={addExpenseTooltip}>
                          <Button variant="outline-primary" className="m-1"
                           onClick={() => {
                             addExpneseRef.current.addExpense();
                             setSelectdGroupid(group.id);
                           }} 
                         ><BsPlusLg/></Button>
                       </OverlayTrigger>  
                       </Col>                       
                     </Row>
                   </Card.Body>
                 </Card> 
               </Container>    
             );
          }
          )}
        </Container>
        <AddGroup
          ref={addGroupRef}
          action={refreshAction}
        />
        <EditGroup
          ref={editGroupRef}
          action={refreshAction}
        />
        <AddExpense
          ref={addExpneseRef}
          groupid={selectedGroupid}
          month={month}
          year={year}
          action={refreshAction}
        />
         <EditExpense
          ref={editExpenseRef}
          action={refreshAction}
          />
      </Container>
      
    ) 
};