import axios from "axios";
import React, { useImperativeHandle, useState } from "react";
import { Button , Modal, Form, Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useAlert } from 'react-alert';
import authService from "../api-authorization/AuthorizeService";
export const AddGroup = React.forwardRef(({action},ref) => {
    const [showAddGroup,setShowAddgroup] = useState(false);
    const [loading,setLoading] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const alert = useAlert();
    useImperativeHandle(ref, () =>({
        addGroup(){
            setShowAddgroup(true);
        },
    }));
    const onSubmit = async (data) => {
      setLoading(true)
        var formData ={
            GroupName : data.groupName
        };
        const token = await authService.getAccessToken();
        await axios.post(
            "api/Groups/",
            formData,
            {
              headers: !token
                ? {}
                : {
                    'Authorization': `Bearer ${token}`,
                  },
            }
        ).then(response =>{
            setLoading(false);
            setShowAddgroup(false);
            action();
            response.data.status === 200 ?
            alert.success(response.data.message) :
            alert.error(response.data.message);
        }).catch(error => {
            console.log(error);    
            alert.error(error.message); 
        });
    };
    return(
        <>
            <Modal
              show={showAddGroup}
              onHide={()=>{setShowAddgroup(false);setLoading(false)}}
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Group</Modal.Title>
              </Modal.Header>
              <Form onSubmit={handleSubmit(onSubmit)}>
              <Modal.Body>
                  <Form.Group className="mb-3">
                    <Form.Label>Group name</Form.Label>
                    <Form.Control type="text" {...register("groupName", { required: true })}  />
                    {errors.groupName && "group name is required"}
                  </Form.Group>          
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" 
                   onClick={()=>{setShowAddgroup(false);setLoading(false);}}>
                    Close
                  </Button>
                  <Button variant="primary" type="submit" disabled={loading}
                    onClick={() => {}}
                  >{loading ? <Spinner animation="border" variant="light" /> : "validate"}</Button>
                </Modal.Footer>
              </Form>
            </Modal>
        </>
    );
});