import React, { useEffect, useState } from "react";
import { Container, Card, Row, Table, Spinner } from "react-bootstrap";
import axios from "axios";
import authService from "../api-authorization/AuthorizeService";
export const Purchases = ({month, year}) =>{
    const [purchases,setPurchases] = useState([{}]) ;
    const [loading,setLoading] = useState(false) ;
    
    useEffect( () =>{
        //get Sales amount for each client from server
        const getPurchasesAmount = async () =>{
            const token = await authService.getAccessToken();
            await axios.get(
                `api/SalesReport/GetPurchasesAmounts?month=${month}&&year=${year}`,
                {
                  headers: !token
                    ? {}
                    : {
                        Authorization: `Bearer ${token}`,
                      },
                }
            ).then(
                (response) => {
                    setPurchases(response.data);
                    setLoading(false);
                }
            ).catch(
                error => {console.log(error);
                    setLoading(false);
                }
            );
        }
        setLoading(true);
        getPurchasesAmount();
        return console.log("echeance unmount") ;
    },[month,year]);
    return (
                <Container className="col-md-6 mb-4">
                    <Card >
                        <Card.Header className="text-center" tag="h2">Purchases</Card.Header>
                        <Card.Body>
                            <Row>
                            <center style={{ display: loading ? "block" : "none" }}>
                                <Spinner animation="grow" size="sm" variant="primary" />
                            </center>
                            <div style={{ display: loading ?  "none" : "block"  }}>
                            <Table hover size="sm">
                        <thead className="thead-light">
                            <tr>
                                <th>
                                    Fournisseur
                                </th>
                                <th>
                                    Total Amount DH
                                </th>    
                            </tr>      
                        </thead>
                        <tbody>
                            {purchases.map( (purchase,idx) =>{
                                return(
                                <tr key={idx}>
                                    <td>
                                        {purchase.provider}
                                    </td>
                                    <td>
                                        {purchase.purchaseAmount}
                                    </td>
                                </tr>
                                );
                            }
                            )}
                        </tbody>
                    </Table>
                            </div>
                          
                            </Row>
                        </Card.Body>
                    </Card> 
                </Container>    
    )
  
};