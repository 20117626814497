import React, { useImperativeHandle, useState, useRef } from "react";
import { Button , Modal, Form, Spinner, Row, Col } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import axios from "axios";
import { useAlert } from 'react-alert';
import authService from "../api-authorization/AuthorizeService";
export const EditExpense = React.forwardRef(({action},ref) =>{
    const [showEditExpense,setShowEditExpense] = useState(false);
    const [loading,setLoading] = useState(false);
    const [currentExpense,setCurrentExpense] = useState({});
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const alert = useAlert() ;
    const euroRef = useRef() ;
    const dirhamRef = useRef();
    const dirhamToEuro = () => {
        euroRef.current.value = (dirhamRef.current.value / 11).toFixed(2);
        setValue("amount_euro", (dirhamRef.current.value / 11).toFixed(2));
      setValue("amount_dh",dirhamRef.current.value);      
    };
    const euroToDirham = () =>{
      dirhamRef.current.value = (euroRef.current.value * 11).toFixed(2) ;
      setValue("amount_dh",(euroRef.current.value * 11).toFixed(2));
      setValue("amount_euro",euroRef.current.value);
    };
    const setdefaultValues = (expense) =>{
      setValue("description",expense.description)
      setValue("amount_dh",expense.amount_dh);
      setValue("amount_euro",expense.amount_euro);
    }
    useImperativeHandle(ref, () =>({
        editExpense(expense){
          console.log(expense);
            setShowEditExpense(true);
            setCurrentExpense(expense);
            setdefaultValues(expense);
            console.log(currentExpense.description);
        },
    }));
    const onSubmit = async (data) =>{
      setLoading(true) ;
        let expense = {
            "Id" : currentExpense.id,
            "GroupId" : currentExpense.groupId,
            "Description" : data.description,
            "month" : currentExpense.month,
            "year" : currentExpense.year,
            "Amount_dh" : data.amount_dh,
            "Amount_euro" : data.amount_euro
        };
        console.log(expense);
        const token = await authService.getAccessToken();
        await axios.put(
            "api/expenses",
            expense,
            {
              headers: !token
                ? {}
                : {
                    'Authorization': `Bearer ${token}`,
                  },
            }
        ).then(response =>{
            setLoading(false);
            setShowEditExpense(false);
            action();
            response.data.status === 200 ?
            alert.success(response.data.message) :
            alert.error(response.data.message);
        }).catch(
           error => {
            setLoading(false);
            setShowEditExpense(false);
            alert.error(error.message);
        });
    };

     return(
        <>
        <Modal
          show={showEditExpense}
          onHide={()=>{setShowEditExpense(false)}}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Expense</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control type="text" {...register("description", { required: true })}/>
                {errors.description && "description is required"}
              </Form.Group>       
            </Row>
            <Row>
                <Col md="6">
                    <Form.Group className="mb-3">
                        <Form.Label>Amount DH</Form.Label>
                        <Form.Control type="text" {...register("amount_dh", { required: true })} 
                        defaultValue={currentExpense.amount_dh}
                        ref={dirhamRef}
                        onChange={
                           dirhamToEuro
                        }
                        />
                        {errors.amount_dh && "amount dirham is required"}
                    </Form.Group>  
                </Col>
                <Col md="6">
                    <Form.Group className="mb-3">
                        <Form.Label>Amount Euro</Form.Label>
                        <Form.Control type="text" {...register("amount_euro", { required: true })} 
                        defaultValue={currentExpense.amount_euro}
                        ref={euroRef}
                        onChange={euroToDirham}
                        />
                        {errors.amount_euro && "amount euro is required"}
                    </Form.Group>  
                </Col>
            </Row>  
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" 
               onClick={()=>{setShowEditExpense(false);setLoading(false);}}>
                Close
              </Button>
              <Button variant="primary" type="submit" disabled={loading}
                onClick={() => {}}
              >{loading ? <Spinner animation="border" variant="light" /> : "validate"}</Button>
            </Modal.Footer>
          </Form>
        </Modal>
        </>
     );
});