import React, { useImperativeHandle, useState } from "react";
import { Button , Modal, Form, Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useAlert } from 'react-alert'
import axios from "axios";
import authService from "../api-authorization/AuthorizeService";
export const EditGroup = React.forwardRef(({action},ref) => {
    const [showEditGroup,setShowEditGroup] = useState(false);
    const [currentGroup,setCurrentGroup] = useState({});
    const [loading,setLoading] = useState(false);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const alert = useAlert() ;
    //const endpointUrl = `api/Groups/UpdateGroup/${currentGroup.Id}`;
    const endpointUrl = "api/Groups/UpdateGroup";
    useImperativeHandle(ref,() =>({
        editGroup(group){
          setShowEditGroup(true);
          setCurrentGroup(group);
          setValue("groupName",group.groupName)
          console.log(currentGroup);
        },
    }));
    const edit = async (formData)=>{
      const token = await authService.getAccessToken();
       await axios.put(
            endpointUrl,
            formData,
            {
              headers: !token
                ? {}
                : {
                    'Authorization': `Bearer ${token}`,
                  },
            }
        ).then(response =>{
            setLoading(false);
            setShowEditGroup(false) ;
            action();
            response.data.status === 200 ?
            alert.success(response.data.message) :
            alert.error(response.data.message);
        }    
        ).catch((error)=>{
            console.log(error);
            alert.error(error.message);
        })
    };
    const onSubmit = async (data) =>{
      setLoading(true) ;
        //when user submit form use axios to send changes to server
        const formData ={
            "Id" : currentGroup.id,
            "GroupName" : data.groupName
        };
        await edit(formData);
        console.log(formData) ;
    };
    return(
          <>
            <Modal
              show={showEditGroup}
              onHide={()=>{setShowEditGroup(false)}}
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Group</Modal.Title>
              </Modal.Header>
              <Form onSubmit={handleSubmit(onSubmit)}>
              <Modal.Body>
                  <Form.Group className="mb-3">
                    <Form.Label>Group name</Form.Label>
                    <Form.Control type="text" {...register("groupName", { required: true })} 
                    />
                    {errors.groupName && "group name is required"}
                  </Form.Group>          
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" 
                   onClick={()=>{setShowEditGroup(false);setLoading(false);}}>
                    Close
                  </Button>
                  <Button variant="primary" type="submit" disabled={loading}
                    onClick={() => { }}
                  >{loading ? <Spinner animation="border" variant="light" /> : "validate"}</Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </>
    );
});