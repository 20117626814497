import axios from "axios";
import React, { useImperativeHandle, useRef, useState } from "react";
import { Button , Modal, Form, Spinner, Row, Col } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useAlert } from 'react-alert';
import authService from "../api-authorization/AuthorizeService";
export const AddExpense = React.forwardRef(({groupid, month, year, action}, ref) =>  {
    const [showAddExpense,setShowAddExpense] = useState(false) ;
    const [loading,setLoading] = useState(false);
    const { register, handleSubmit, setValue , formState: { errors } } = useForm();
    const alert = useAlert();
    const dirhamRef = useRef();
    const euroRef = useRef() ;
    const dirhamToEuro = () =>{
        euroRef.current.value = (dirhamRef.current.value / 11).toFixed(2) ;
        setValue("amount_euro",(dirhamRef.current.value / 11).toFixed(2));
        setValue("amount_dh",dirhamRef.current.value);      
    };
    const euroToDirham = () =>{
        dirhamRef.current.value = (euroRef.current.value * 11).toFixed(2) ;
        setValue("amount_dh",euroRef.current.value * 11);
        setValue("amount_euro",euroRef.current.value); 
    };
    useImperativeHandle(ref, () => ({
        addExpense(){
            setShowAddExpense(true);
        },
    }));
    const onSubmit = async (data) =>{
      setLoading(true) ;
        let expense = {
            "GroupId" : groupid,
            "Description" : data.description,
            "month" : month,
            "year" : year,
            "Amount_dh" : data.amount_dh,
            "Amount_euro" : data.amount_euro
        };
        const token = await authService.getAccessToken();
        await axios.post(
            "api/expenses",
            expense,
            {
              headers: !token
                ? {}
                : {
                    'Authorization': `Bearer ${token}`,
                  },
            }
        ).then(response =>{
            action();
            setLoading(false);
            setShowAddExpense(false);
            response.data.status === 200 ?
            alert.success(response.data.message) :
            alert.error(response.data.message);
        }).catch(
           error => {
            alert.error(error.message);
            setLoading(false);
            setShowAddExpense(false);
        });
    };
    return(
        <>
        <Modal
          show={showAddExpense}
          onHide={()=>{setShowAddExpense(false)}}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Expense</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control type="text" {...register("description", { required: true })}  />
                {errors.description && "description is required"}
              </Form.Group>       
            </Row>
            <Row>
                <Col md="6">
                    <Form.Group className="mb-3">
                        <Form.Label>Amount DH</Form.Label>
                        <Form.Control type="text" {...register("amount_dh", { required: true })} 
                        ref={dirhamRef}
                        onChange={
                           dirhamToEuro
                        }
                        />
                        {errors.amount_dh && "amount dirham is required"}
                    </Form.Group>  
                </Col>
                <Col md="6">
                    <Form.Group className="mb-3">
                        <Form.Label>Amount Euro</Form.Label>
                        <Form.Control type="text"  {...register("amount_euro", { required: true })} 
                         ref={euroRef}
                        onChange={euroToDirham}
                        />
                        {errors.amount_euro && "amount euro is required"}
                    </Form.Group>  
                </Col>
            </Row>  
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" 
               onClick={()=>{setShowAddExpense(false);setLoading(false);}}>
                Close
              </Button>
              <Button variant="primary" type="submit" disabled={loading}
                onClick={() => { }}
              >{loading ? <Spinner animation="border" variant="light" /> : "validate"}</Button>
            </Modal.Footer>
          </Form>
        </Modal>
        </>
    );  
});