import React, { useEffect, useState } from "react";
import { Container, Table, Card, Row, Spinner } from "react-bootstrap";
import axios from "axios";
import { useAlert } from "react-alert";
import authService from "../api-authorization/AuthorizeService";
export const Totalamounts = ({month,year}) =>{
    const [loading,setLoading] = useState(false);
    const [totalamounts,setTotalamounts] = useState([{}]);
    const alert = useAlert();
    useEffect( () =>{
        const getTotalAmounts = async () =>{
            const token = await authService.getAccessToken();
            await axios.get(
                `api/expenses/GetTotalAmounts?month=${month}&&year=${year}`,
                {
                   headers: !token
                     ? {}
                     : {
                         'Authorization' : `Bearer ${token}`,
                       },
                }
            ).then(
                 response => {
                     setTotalamounts(response.data);
                     setLoading(false);
                 }
            ).catch(
                 error =>{
                     console.log(error.message);
                     alert.error(error.message);
                 }
            );
        }
        setLoading(true);
        getTotalAmounts();
        return console.log("total amounts unmount");
    },[month,year]);
    return(
        <Container className="col-md-6 mb-4">
            <Card >
                <Card.Header className="text-center"  tag="h2">Total Amounts</Card.Header>
                <Card.Body>
                    <Row>
                        <center style={{ display: loading ? "block" : "none" }}>
                            <Spinner animation="grow" size="sm" variant="primary" />
                        </center>
                        <div style={{ display: loading ?  "none" : "block"  }}>
                            <Table hover size="sm" >
                                <thead className="thead-light">
                                    <tr>
                                        <th>
                                            Group
                                        </th>
                                        <th>
                                            Total Amount DH
                                        </th>  
                                        <th>
                                            Total Amount euro
                                        </th>    
                                    </tr>      
                                </thead>
                                <tbody>
                                    {totalamounts.map( (total,idx) =>{
                                        return(
                                        <tr key={idx}>
                                            <td>
                                                {total.group}
                                            </td>
                                            <td>
                                                {total.amountdh}
                                            </td>
                                            <td>
                                                {total.amounteuro}
                                            </td>
                                        </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div> 
                    </Row>
                </Card.Body>
            </Card> 
        </Container>
    );
}