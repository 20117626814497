import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { FetchData } from './components/FetchData';
import { Group } from './components/Pages/Group';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import './custom.css'
import { Dashboards } from './components/Pages/Dashboards';
import { Echeance } from './components/Pages/Echeance';
export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        {/* 
        <Route path='/counter' component={Counter} /> */}
            <AuthorizeRoute exact path='/' component={Group} />
            <AuthorizeRoute path='/expenses' component={Group} />
            <AuthorizeRoute path='/dashboads' component={Dashboards} />
            <AuthorizeRoute path='/echeances' component={Echeance} />
            <AuthorizeRoute path='/fetch-data' component={FetchData} />
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
      </Layout>
    );
  }
}
