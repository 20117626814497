import React, { useEffect, useState } from "react";
import { Container, Card, Row, Table, Spinner } from "react-bootstrap";
import axios from "axios";
import authService from "../api-authorization/AuthorizeService";
export const Sales = ({month, year}) =>{
    const [sales,setSales] = useState([{}]) ;
    const [loading,setLoading] = useState(false);
   

    useEffect(() =>{
        //get Sales amount for each client from server
        const getSalesAmounts = async () =>{
            const token = await authService.getAccessToken();
            await  axios.get(
                 `api/SalesReport/GetSalesAmounts?month=${month}&&year=${year}`,
                 {
                   headers: !token
                     ? {}
                     : {
                         'Authorization' : `Bearer ${token}`,
                       },
                 }
            ).then(
                 (response) => {
                     setSales(response.data);
                     setLoading(false);
                 }
            ).catch(
                error => {console.log(error);
                     setLoading(false);
                }
                 
             );
        }
        setLoading(true);
       getSalesAmounts();
       return console.log("Sales unmount") ;
    },[month,year]);
    return (
        <>
             
<Container className="col-md-6 mb-4">
    <Card >
        <Card.Header className="text-center"  tag="h2">Sales</Card.Header>
        <Card.Body>
            <Row>
            <center style={{ display: loading ? "block" : "none" }}>
                <Spinner animation="grow" size="sm" variant="primary" />
            </center>
                <div style={{ display: loading ?  "none" : "block"  }}>
                <Table hover size="sm" >
                    <thead className="thead-light">
                        <tr>
                            <th>
                                Client
                            </th>
                            <th>
                                Total Amount DH
                            </th>    
                        </tr>      
                    </thead>
                    <tbody>
                        {sales.map( (sale,idx) =>{
                            return(
                            <tr key={idx}>
                                <td>
                                    {sale.client}
                                </td>
                                <td>
                                    {sale.salesAmount}
                                </td>
                            </tr>
                            );
                        }
                        )}
                    </tbody>
                </Table>
                </div>
               
            </Row>
        </Card.Body>
    </Card> 
    </Container>    
        </>
               
    )
  
};