import React, { useEffect, useRef, useState } from "react";
import { Container, Col, Form, Row, Button } from "react-bootstrap";
import { Totalamounts } from "../dashboards/Totalamounts";
import axios from "axios";
import { ExpenseDate } from "./Date";
import authService from "../api-authorization/AuthorizeService";
export const Dashboards = () =>{
  var date = new Date(); 
    const [month,setMonth] = useState((date.getMonth()+1).toString());
    const [year,setYear] = useState(date.getFullYear());
    const MonthRef = useRef();
    const YearRef = useRef();
    const [years,setYears] = useState([]);
    const getYears = async () =>{
      const token = await authService.getAccessToken();
       await  axios.get(
           "api/SalesReport/Getyears" ,
           {
             headers: !token
               ? {}
               : {
                   'Authorization' : `Bearer ${token}`,
                 },
           }
         ).then(
           response => {
             setYears(response.data) ;
           }
         ).catch(
           error => {
             console.log(error);
           }
         );
   };
    useEffect(() =>{
        getYears();
        return console.log("dashboards unmount");
    },[]);
    return(
        <Container>
            <ExpenseDate
            month={month}
            year={year}
            title="Total amounts"
            />
            <Container border="secondary"> 
            <Row>
                <Col md="2">
                </Col>
                <Col md="4">
                    <Form.Select ref={MonthRef}>
                        <option  value={month}></option>
                        <option  value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">Mars</option>
                        <option value="4">April</option>
                        <option value="5">Mai</option>
                        <option value="6">June</option>
                        <option value="7">Julie</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </Form.Select>
                </Col>
                <Col  md="4">
                    <Form.Select ref={YearRef}>
                    <option  value={year}></option>
                    {
                        years.map((year,idx) =>{
                        return (<option key={idx} value={year}>{year}</option>);
                        })
                    }
                    </Form.Select>     
                </Col>
                <Col md="1">
                    <Button variant="outline-primary"
                    onClick={
                    ()=>{
                        setMonth(MonthRef.current.value);
                        setYear(YearRef.current.value);
                    }
                    }>Laod
                    </Button>
                </Col>
            </Row>
        </Container>
            <Container className="mt-4">
                <Totalamounts
                month={month}
                year={year}
                />
        </Container>
        </Container>
       
    )
}